/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Container, Box, Flex, NavLink } from '@theme-ui/components'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text} from '@theme-ui/components';
import NavBar from '../components/NavBar';
import { graphql } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@theme-ui/components'
import Seo from '../components/seo';


const index = ({ data }) => {
  const posts = data.allMdx.nodes
  console.log(posts);
  return (
    <div>
    <Seo />
      <NavBar />
      <div sx={{
      width: '100vw',
      minWidth: '100vw',
      minHeight: '90vh',
      backgroundColor: 'primary',
      position: 'relative',
    }}>

    <StaticImage
            src="../images/ame-hero-v2a.png"
            alt=""
            objectFit="cover"
            objectPosition="100% 0%"
            style={{ position: "absolute", zIndex: 1, height: '100%', width: '100%' }}
        />

        <div sx={{
          backgroundColor: "background",
          opacity: 0.8,
        height: "100%",
        width: "100%",
        position: "absolute",
        zIndex: 2,
        }}
        >
        </div>

  
    <Container sx={{
      height: "100%",
        zIndex: 100,
        position: "relative",
    }}>
    <Box py={6} color="white">
    <Grid gap={2} columns={[2, '3fr 1fr']}>
    <Box>
    <h1
    sx={{
      color: 'white',
      fontSize: '48px',
      fontWeight: '500',
      lineHeight: '1',
    }}>The
      <span sx={{
        color: 'secondary',
      }}> Asset Management Education</span> System you can trust.
    </h1>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>
    </Box>
      <Box></Box>
    </Grid>
    
    <h2>
    16 - 18 word tagline that will get used in marketing and show up in search results.
    </h2>
    <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ornare eros, a varius nunc. Cras in porttitor diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ornare eros, a varius nunc. Cras in porttitor diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ornare eros, a varius nunc. Cras in porttitor diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec aliquam ornare eros, a varius nunc. Cras in porttitor diam.
    </p>
    
    <Button as={GatsbyLink} to='contact' mr={2}>Get in Touch!</Button>
    </Box>
    </Container>
    </div>
    <Container>
    <Box py={4}>
    <Text py={3} as="h2">
      Newest Courses
    </Text>
    <Grid width={[192, null, 192]}>

    {posts.map(post => {

          return (
            <Card key={post.slug}
              sx={{
                maxWidth: '100%',
                padding: 1,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'primary',
              }}>
              <GatsbyImage image={getImage(post.frontmatter.featuredImage)}
              />
              <Text as="h3" p={2} sx={{
                color: 'secondary',
              }}>{post.frontmatter.title} - {post.frontmatter.subTitle}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
                fontSize: '12px',
              }}>{post.frontmatter.company}</Text>
              <Text as="p" p={2} sx={{
                color: 'text',
              }}>{post.frontmatter.excerpt}</Text>
                    <Link p={2} sx={{ color: 'primary' }} to={post.slug} as={GatsbyLink}>
        Read More...
      </Link>
              
              </Card>
          )
        })}

        
    </Grid>
    <Box py={4} mx={'auto'}>
    <Flex
    sx={{
      alignItems: 'center',
    }}>
    <Button as={GatsbyLink} to='courses' mx={'auto'}>See All Courses!</Button>
    </Flex>
    </Box>
    </Box>
    <Divider sx={{
      backgroundColor: 'primary',
    }}/>
    <Text py={3} as="h2">
      About AME Systems
    </Text>
    <Text py={3} as="p">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non lorem pulvinar, venenatis justo at, sollicitudin dui. Praesent et turpis orci. In vitae consectetur turpis. Fusce id vulputate ex. Vivamus non lectus ut sapien elementum ornare porta nec ipsum. Donec viverra dapibus tempor. In hac habitasse platea dictumst. Maecenas est libero, placerat at odio non, aliquet imperdiet lectus. Maecenas non diam quis justo suscipit hendrerit. Vestibulum at lacinia ligula. Aliquam imperdiet lacus arcu, sit amet porta arcu rutrum et. Maecenas nibh lorem, lacinia at quam sit amet, commodo posuere nibh. Sed venenatis ipsum nec velit egestas fermentum. 
    </Text>
    <Text py={3} as="p">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non lorem pulvinar, venenatis justo at, sollicitudin dui. Praesent et turpis orci. In vitae consectetur turpis. Fusce id vulputate ex. Vivamus non lectus ut sapien elementum ornare porta nec ipsum. Donec viverra dapibus tempor. In hac habitasse platea dictumst. Maecenas est libero, placerat at odio non, aliquet imperdiet lectus. Maecenas non diam quis justo suscipit hendrerit. Vestibulum at lacinia ligula. Aliquam imperdiet lacus arcu, sit amet porta arcu rutrum et. Maecenas nibh lorem, lacinia at quam sit amet, commodo posuere nibh. Sed venenatis ipsum nec velit egestas fermentum. 
    </Text>
    <Text py={3} as="p">
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non lorem pulvinar, venenatis justo at, sollicitudin dui. Praesent et turpis orci. In vitae consectetur turpis. Fusce id vulputate ex. Vivamus non lectus ut sapien elementum ornare porta nec ipsum. Donec viverra dapibus tempor. In hac habitasse platea dictumst. Maecenas est libero, placerat at odio non, aliquet imperdiet lectus. Maecenas non diam quis justo suscipit hendrerit. Vestibulum at lacinia ligula. Aliquam imperdiet lacus arcu, sit amet porta arcu rutrum et. Maecenas nibh lorem, lacinia at quam sit amet, commodo posuere nibh. Sed venenatis ipsum nec velit egestas fermentum. 
    </Text>
    </Container>
    </div>
  )
}

export default index

export const pageQuery = graphql`
  query {
    allMdx(sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        slug
        excerpt(pruneLength: 250)
        frontmatter {
          category
          excerpt
          author
          company
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          title
          subTitle
          date(formatString: "YYYY MMMM Do")
          modules {
            moduleNumber
            title
            content
          }
        }
      }
    }
  }
`
